.card {
  display: block;
  align-items: center;
  padding: 24px 32px;
  gap: 8px;
  border-radius: 8px;
  background: var(--neutral0);
  box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.08);
  margin: 16px 8px;
}
