.modalWrapper {
  &::before {
    content: '';
    display: block;
    background: var(--primary200);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    opacity: 50%;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: fit-content;
    width: 600px;

    .container {
      .buttonBar {
        display: flex;

        button {
          border: 1px solid var(--secondary700);
          background-color: transparent;
          color: var(--neutral800);
          border-radius: 4px;
          margin-bottom: 0;
          margin-right: 4px;
          min-width: 0;
          padding: 20px 0;

          &.selected {
            border: 3px solid var(--secondary600);
            border-radius: 4px;
            padding: 18px 0;
            color: var(--neutral800);
            background-color: transparent;
          }
        }
      }

      .helper {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: var(--neutral700);
        margin-top: 8px;
        margin-bottom: 32px;
      }

      button {
        width: 100%;
        margin-bottom: 16px;
      }
    }

    .subtitle {
      font-weight: 500;
      color: var(--neutral800);
    }

    .helper {
      font-size: 13px;
      color: var(--neutral700);
    }
  }
}
