.chatBotTester {
  * {
    font-family: Nunito, Arial, Helvetica, sans-serif !important;
  }

  .bot {
    position: fixed;
    height: 650px;

    button {
      padding: 8px 16px !important;
    }

    ol, ul {
      li {
        list-style: inherit !important;
      }
    }

    @media screen and (max-height: 875px) {
      height: 60%;
      & > div {
      display: flex;
      flex-direction: column;
      }
    }
  }

  .modalWrapper {
    &::before {
      content: '';
      display: block;
      background: var(--primary200);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100000;
      opacity: 50%;
    }

    .modal {
      position: fixed;
      z-index: 1000000;
      overflow-x: hidden;
      overflow-y: hidden;
      max-height: 98vh;
      width: 528px;
      height: 875px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: flex-start;

      & > div {
        margin: 0;
      }
    }
  }

  .dropdownLeft {
    padding-right: 4px;
  }

  .dropdownRight {
    padding-left: 4px;
  }

  .languageSelectorLabel {
    display: flex;
    align-items: center;

    .imageContainer {
      background-size: contain;
      height: 16px;
      width: 24px;
      margin-right: 8px;
    }
  }
}

.tooltip {
  font-size: 20px !important;
  padding: 8px 16px !important;
  z-index: 10000000 !important;
}
