.wrapper {
  width: 600px;
}

.placeholder {
  height: 120px;
  position: relative;
  width: 100%;

  .placeholderContent {
    height: 80px;
    border: 1px dashed var(--neutral400);
    border-radius: 8px;
    width: 100%;
  }
}

.borderContainer {
  display: flex;

  .border {
    border-left: 2px solid var(--neutral400);
    margin-left: 24px;
  }
}

.nodeWrapper {
  position: relative;
  width: 100%;
}

.connectorLine {
  border-top: 2px solid var(--neutral400);
  position: absolute;
  top: 58px;
}

.count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2389ff;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.nodeInletLine {
  height: 8px;
  width: 2px;
  background: var(--neutral400);
  position: absolute;
  top: 0px;
  left: 24px;
}

.nodeOutletLine {
  height: 8px;
  width: 2px;
  background: var(--neutral400);
  position: absolute;
  bottom: 0px;
  left: 24px;
}
