.header {
  position: fixed;
  width: fill-available;
  background: white;
  z-index: 100000;

  &.sideBarOpen {
    width: fill-available;
    left: 240px;
    transition: all 0.5s linear;
  }

  .appTitle {
    display: flex;
    align-items: flex-start;

    img {
      height: 56px;
    }

    h4 {
      margin: 12px 0px 0px 12px;
      border-left: 1px solid;
      padding-left: 12px;
      height: 32px;
    }

    .envLabel {
      margin-left: 8px;
      padding: 0px 6px;
      background-color: var(--tertiary300);
      font-size: 18px;
      margin-top: 12px;
      font-weight: 500;
    }
  }

  .avatar {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: var(--neutral700);

    .avatarImg {
      border-radius: 50%;
      border: 2px solid var(--neutral200);
    }
  }

  .dropdownMenu {
    padding: 0;
    border-radius: 8px;
    min-width: 120px;

    .userActionItem {
      display: flex;
      padding: 16px;
      cursor: pointer;
      align-items: center;

      svg {
        margin-right: 12px;
      }

      &:hover,
      &:active {
        background-color: var(--neutral100);
      }

      &.selected {
        background-color: var(--secondary100);
        justify-content: space-between;

        svg {
          margin-right: 0 !important;
          font-size: 16px;
        }
      }
    }
  }

  .languageSwitcher {
    display: flex;
    align-items: center;
    margin: 0 8px;
    cursor: pointer;
  }
}
