.conversationDesigner {
  display: flex;
  padding-top: 64px;
  overflow-y: hidden;

  &.isMessageBarOpen {
    height: calc(100vh - 48px);
  }

  .newDesigner {
    padding-top: 64px;
    margin-left: 30%;

    .contentSwitcher {
      & button:hover {
        background: transparent;
      }
    }

    .languageSelectorLabel {
      display: flex;
      align-items: center;

      .imageContainer {
        background-size: contain;
        height: 16px;
        width: 24px;
        margin-right: 8px;
      }
    }

    .currentTemplateCard {
      width: 100%;
      display: flex;
      background-color: var(--neutral100);
      box-shadow: none;
      font-size: 16px;

      &:hover {
        border: 2px solid var(--secondary400);
        cursor: pointer;
        padding: 22px 30px;
      }

      & > div {
        font-size: 14px;
        font-weight: 400;

        svg {
          font-size: 40px;
          margin-right: 16px;
        }
      }
    }

    .scheduleToggle {
      display: flex;
      align-items: center;
      margin-top: 4px;

      & > div {
        margin-right: 8px;
      }

      label {
        cursor: pointer;
      }
    }

    .buttonBar {
      display: flex;
      justify-content: end;
    }

    .errorMsg {
      font-size: 14px;
      color: var(--error600);
      margin-top: 4px;
    }
  }

  .miniMapPanel {
    width: 20%;
    background-color: var(--neutral0);
    box-shadow: 4px 2px 16px 0px rgba(28, 48, 75, 0.08);
    position: relative;

    .designerNameBackground {
      position: relative;
      z-index: 10000;
      background-color: var(--neutral0);
      padding: 4px;

      .designerNameCard {
        margin: 24px 12px;
        padding: 12px;
        background-color: var(--neutral100);
        border: 1px solid var(--neutral200);
        box-shadow: none;

        .heading {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.6px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }

        .designerName {
          font-weight: 700;
          line-height: 16px;
        }
      }
    }

    .miniMap {
      padding: 16px 32px;
      margin-top: 12px;
    }
  }

  .wrapper {
    padding-top: 16px;
    width: 100%;
  }
  .designer {
    display: flex;
    justify-content: space-between;
  }

  .leftPane {
    padding: 12px;
    margin-top: 12px;
    background-color: var(--neutral0);
    width: 100%;

    @media screen and (max-width: 1366px) {
      width: 70%;
    }
  }

  .rightPane {
    width: 25%;
    padding: 0 32px;
    background-color: var(--neutral100);

    @media screen and (max-width: 1366px) {
      width: 30%;
    }
  }

  .convesationItemsPane {
    h4 {
      margin-top: 16px;
      margin-bottom: 8px;
      font-size: 19px;
      font-weight: 400;
    }
  }

  .convesationItem {
    padding: 0;
    border-radius: 8px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    background-color: var(--neutral0);
    cursor: grab;
    box-shadow: (28, 48, 75, 0.08);
    height: 48px;

    &.disabled {
      opacity: 0.75 !important;
      cursor: not-allowed;

      .label {
        margin-left: 32px;
      }
    }

    .accent {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 8px;
      height: 48px;
    }

    &:active:not(.disabled) {
      cursor: grabbing;
    }
    svg {
      margin-right: 12px;
    }
  }

  .designerPane {
    display: flex;
    flex-direction: column;
    align-items: center;

    .emptyList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 60vh;
      width: 100%;

      & > div {
        margin: 8px 0;
      }

      svg {
        font-size: 60px;
      }
    }

    .list {
      margin-top: 0;
      height: 85vh;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
      width: 100%;
    }
  }

  .node {
    display: block;
    box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.08);
    background-color: white;
    border-radius: 8px;
    min-width: 240px;
    min-height: 56px;
    cursor: pointer;

    &.open {
      padding: 12px 16px;
    }

    .accent {
      min-width: 6px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background-color: #ebebee;
      margin-right: 12px;
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 16px;
        font-weight: 500;
        padding-top: 8px;
      }

      .subtitle {
        font-size: 12px;
        color: #8e8e8e;
        padding-bottom: 8px;
      }
    }
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    width: 100%;
    border-bottom: 1px solid var(--neutral200);

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 12px;
    }

    .languageSelectorLabel {
      display: flex;
      align-items: center;

      .imageContainer {
        background-size: contain;
        height: 16px;
        width: 24px;
        margin-right: 8px;
      }
    }

    .toolbarItem {
      display: flex;
      align-items: center;
      background-color: transparent;
      color: var(--neutral800);
      cursor: pointer;
      min-width: 32px;
      padding: 24px 12px;
      margin-right: 4px;
      font-size: 12px;
      text-transform: none;

      svg {
        margin-right: 4px;
      }

      &:hover {
        background-color: var(--neutral100);
      }

      &:active {
        background-color: var(--neutral200);
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    .dropdown {
      padding: 0;

      .dropdownItem {
        display: flex;
        padding: 12px;
        cursor: pointer;
        align-items: center;
        background-color: transparent;
        color: var(--neutral800);
        justify-content: flex-start;
        width: 100%;

        &:hover,
        &:active {
          background: var(--secondary100);
        }

        &:disabled {
          opacity: 0.5;
        }

        svg {
          margin-right: 12px;
        }
      }
    }

    .chipButton {
      font-weight: 600;
      font-size: 12px;
    }

    .separator {
      border-left: 1px solid var(--neutral300);
      height: 28px;
      margin: 0 8px;
    }

    .languageSelector {
      min-width: 140px;
      display: flex;
      justify-content: space-between;
      height: 28px;
      margin-right: 8px;
      background-color: transparent;
      color: var(--neutral800);
      padding: 20px 12px;

      &:hover,
      &:active {
        background: var(--neutral100);
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.designerNode {
  display: flex;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  margin: 8px 0;
  box-shadow: 0px 4px 16px 0px rgba(28, 48, 75, 0.08);
  border: 1px solid var(--neutral100);

  &.open {
    .body {
      padding: 16px;

      & > div {
        margin-bottom: 12px;
      }
    }

    .controls {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 0 !important;

      .error {
        color: var(--error600);
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  .accent {
    width: 12px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #ebebee;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    width: 100%;

    .errorMsg {
      display: flex;
      align-items: center;
      font-weight: 500;

      svg {
        margin-right: 4px;
        color: var(--error400);
      }
    }

    .label {
      margin-bottom: 8px;
    }

    .titleBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      display: flex;
      align-items: center;
      height: 40px;

      .dragIndicator {
        background-color: transparent !important;
        min-width: 0 !important;
        color: var(--neutral700);
        padding: 0 !important;
        cursor: grab;

        &:active {
          cursor: grabbing;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .previewText {
      margin-left: 8px;
      margin-bottom: 4px;

      p {
        margin: 0;
      }

      li {
        display: flex;
        &::before {
          content: '•';
          margin-right: 8px;
        }
      }
    }

    .buttonBar {
      display: flex;
      justify-content: flex-end;

      a {
        margin-right: 16px;
      }

      svg {
        margin-right: 4px;
      }
    }
  }
}

.schedulingHintCard {
  width: 600px;

  .accent {
    background: var(--secondary400);
  }

  .body {
    padding: 12px;

    .title {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }

    .subtitle {
      margin-left: 32px;
    }
  }
}
