.drawerWrapper {
  &::before {
    content: '';
    display: block;
    background: var(--primary200);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    opacity: 50%;
  }

  .drawer {
    position: fixed;
    z-index: 1000000;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    right: 0;
    width: 30%;
    animation: slideInFromRight 0.5s ease-out forwards;

    .header {
      font-size: 14px;
      padding: 4px 0px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--primary700);
      margin-top: 8px;
    }

    .scheduleToggle {
      display: flex;
      align-items: center;
      margin-top: 4px;

      & > div {
        margin-right: 8px;
      }

      label {
        cursor: pointer;
        font-size: 16px;
      }
    }

    .warning {
      color: var(--error400);
    }

    .error {
      color: var(--error600);
    }

    .buttonBar {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-left: 12px;
      }
    }
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
