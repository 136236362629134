.app {
  margin-left: 80px;

  button {
    margin-top: 0px !important;
    line-height: 1 !important;
  }

  form {
    padding: 0 !important;
  }

  li {
    list-style: none !important;
  }

  input[type='checkbox'] {
    margin: -1px 4px !important;
  }

  .calendar-day {
    button {
      background-color: none;
    }
  }

  @media (max-width: 768px) {
    width: fit-content;
  }
}

.releaseNotification {
  padding: 90px;

  &.messageBarOpen {
    top: 120px;
  }

  &.sideBarOpen {
    width: fill-available;
    left: 196px;
    margin-left: 56px;
    transition: all 0.5s linear;
  }

  .subtitle {
    width: 50%;
  }

  .bannerImage {
    background: url('~@resources/Construction.png');
    height: 250px;
    background-repeat: no-repeat;
  }
}
