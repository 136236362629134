.conversationPreview {
  .container {
    width: calc(100vw - 200px);
    height: 80vh;

    &.sideBarOpen {
      width: calc(100vw - 336px);
    }
  }

  .controls {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      width: 26px !important;
      padding: 4px;
      border: none;
      border-radius: 0;
      background: #fefefe;
      border-bottom: 1px solid rgb(238, 238, 238);
      color: var(--neutral800);
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      min-width: 0 !important;

      &:disabled {
        background: #fefefe;
        color: var(--neutral600);
      }

      &:hover {
        background: var(--secondary100);
      }
    }
  }

  .choiceBlock {
    margin-top: 16px;
  }
}
