.saveDialog {
  .modalWrapper {
    &::before {
      content: '';
      display: block;
      background: var(--primary200);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100000;
      opacity: 50%;
    }

    .modal {
      position: fixed;
      z-index: 1000000;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: fit-content;
      top: 20%;
      left: 40%;
      width: 30%;

      .buttonBar {
        display: flex;
        justify-content: end;
        align-items: center;

        & > button {
          margin-left: 8px;
        }
      }
    }
  }
}
