.historyDialog {
  .modalWrapper {
    &::before {
      content: '';
      display: block;
      background: var(--primary200);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100000;
      opacity: 50%;
    }

    .modal {
      position: absolute;
      z-index: 1000000;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: fit-content;
      top: 20%;
      left: 25%;
      width: 50%;

      .key {
        margin-right: 12px;
      }
    }
  }
}
