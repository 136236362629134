.notificationProvider {
  width: auto !important;
  margin-right: 16px;

  & > div:has(> .hasMultipleNotifications) {
    transform: translate(-8px, 56px) !important;
  }

  .dropdownButton {
    margin-top: 0;
  }

  .dropdown {
    position: relative;
    left: 200px;
    width: auto;
    padding: 0;
    max-height: 280px;
    overflow-y: auto;

    &.hasMultipleNotifications {
      top: 10px;
      left: 0;
      background-color: transparent;
      box-shadow: none;
  
      .clearAllButtonContainer {
        display: flex;
        justify-content: end;
        margin-right: 12px;
        button {
          height: 40px;
          line-height: 40px;
          width: 40px;
          min-width: 40px !important;
          padding: 16px 12px;
          display: flex;
          justify-content: center;
          gap: 16px;
          align-items: center;
          border-radius: 160px;
          background-color: var(--neutral100);
          color: var(--neutral800);
          font-weight: 500;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  
          .clearAllText {
            font-size: var(--fontSizeMedium);
            text-transform: uppercase;
            display: none;
          }
          .clearAllIcon {
            display: flex;
            align-items: center;
          }
  
          &:hover {
            width: fit-content;
            justify-content: space-between;
            padding: 16px 8px 16px 16px;
          }
  
          &:hover .clearAllText {
            display: inline-block;
          }
        }
      }
    }
    
    .noNotificationsCard {
      display: flex;
      align-items: center;

      .title {
        width: max-content;
      }
    }
  }
}
