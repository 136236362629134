.drawerWrapper {
  &::before {
    content: '';
    display: block;
    background: var(--primary200);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    opacity: 50%;
  }

  .drawer {
    position: fixed;
    z-index: 1000000;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    right: 0;
    width: 80%;
    animation: slideInFromRight 0.5s ease-out forwards;

    .header {
      font-size: 14px;
      padding: 4px 0px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--primary700);
      margin-top: 8px;
    }

    .languageSelectorLabel {
      display: flex;
      align-items: center;

      .imageContainer {
        background-size: contain;
        height: 16px;
        width: 24px;
        margin-right: 8px;
      }
    }

    .languageSelector {
      min-width: 140px;
      display: flex;
      justify-content: space-between;
      height: 28px;
      margin-right: 8px;
      background-color: transparent;
      color: var(--neutral800);
      padding: 20px 12px;
      border: 1px solid var(--neutral400);

      &:hover,
      &:active {
        background: var(--neutral100);
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    .dropdown {
      padding: 0;

      .dropdownItem {
        display: flex;
        padding: 12px;
        cursor: pointer;
        align-items: center;
        background-color: transparent;
        color: var(--neutral800);
        justify-content: flex-start;
        width: 100%;

        &:hover,
        &:active {
          background: var(--secondary100);
        }

        &:disabled {
          opacity: 0.5;
        }

        svg {
          margin-right: 12px;
        }
      }
    }
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
