.miniMap {
  width: stretch;
  position: relative;
  height: 80vh;

  .scrollView {
    width: calc(100% + 52px);
    position: absolute;
    background: var(--neutral200);
    left: -24px;
  }

  .borderContainer {
    display: flex;
    position: relative;

    .border {
      border-left: 2px solid var(--neutral300);
      padding-left: 16px;
    }
  }

  .connectorLine {
    border-top: 2px solid var(--neutral300);
    position: absolute;
    top: 22px;
    width: 16px;
    left: -16px;
  }

  .nodeWrapper {
    position: relative;
    width: 100%;
  }

  .node {
    border-radius: 4px;
    border: 1px solid var(--neutral100);
    background: var(--neutral100);
    margin: 4px 0;
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
      color: var(--error400);
    }
    
    .accent {
      width: 32px;
      height: 38px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      display: flex;
      align-items: center;
    }
  }
}
